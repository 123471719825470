import { format, parse } from "date-fns"
import { fr } from "date-fns/locale"

export function parseDate(dateString: string): Date {
  return parse(dateString, "yyyy-MM-dd", new Date())
}

export function parseOptionalDate(dateString: string | null): Date | null {
  if (dateString == null) {
    return null
  }

  return parse(dateString, "yyyy-MM-dd", new Date())
}

export function formatDate(date: Date, pattern = "PP"): string {
  try {
    return format(date, pattern, { locale: fr })
  } catch (e) {
    return `${date}, ${e.message}`
  }
}

export function formatDateInterval(dateStart: Date, dateEnd: Date | null): string {
  const dateStartFormat = formatDate(dateStart, "PPP")

  if (dateEnd == null) {
    return `le ${dateStartFormat}`
  }

  const dateEndFormat = formatDate(dateEnd, "PPP")

  if (dateStartFormat === dateEndFormat) {
    return `le ${dateStartFormat}`
  }

  if (dateStart.getFullYear() !== dateEnd.getFullYear()) {
    return `du ${dateStartFormat} au ${dateEndFormat}`
  }

  if (dateStart.getMonth() !== dateEnd.getMonth()) {
    return `du ${formatDate(dateStart, "d MMMM")} au ${dateEndFormat}`
  }

  return `du ${formatDate(dateStart, "d")} au ${dateEndFormat}`
}
